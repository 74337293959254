import React, { Component } from "react"
import styled from "styled-components"


export const StyledMailBox = styled.div`
  display: flex;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Label = styled.label`
  color: rgb(139, 149, 158);
  position: absolute;
  top: 1.22rem;
  left: 0px;
  transform-origin: 0px 0px 0px;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
  pointer-events: none;
  user-select: none;
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Input = styled.input`
  width: 100%;
  min-height: 3rem;
  padding-top: 0.95rem;
  padding-bottom: 0px;
  line-height: 1.5em;
  background: transparent none repeat scroll 0% 0%;
  color: rgb(25, 28, 31);
  text-align: left;
  outline: currentcolor none medium !important;
`

export const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgb(206, 210, 214);
  position: absolute;
  bottom: 0px;
`

export const BorderFocus = styled.div`
  height: 2px;
  width: 100%;
  background-color: rgb(255, 0, 135);
  position: absolute;
  bottom: 0px;
  transform: scaleX(0);
  transform-origin: left center 0px;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s, background-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
`

export const TextField = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 0s ease 0s;
  min-height: 5.5rem;
  flex: 1 1 0%;
  text-align: left;
  margin-right: 0px;
  width: 15rem;


  ${props => props.isFocused && `
    & ${BorderFocus} {
      transform: scaleX(1);
    }

    & ${Label} {
      transform: scale(0.75) translateY(calc(-100% - 0.275rem))
    }
  `};
`

export const Result = styled.small`
  color: ${ props => props.formInvalid ? "#ff2d26" : "#007832" };
`


class MailBox extends Component {

  render() {
    return (
      <StyledMailBox>
        <TextField isFocused={this.props.formFocused}>
          <InputContainer>
            <Label>Correo electrónico</Label>
            <Input
              type="email" name="email"
              ref={this.props.emailInput}
              onFocus={this.props.handleOnFocus}
              onBlur={this.props.handleOnBlur}
              onInput={this.props.handleOnInput}
            />
            <Border></Border>
            <BorderFocus></BorderFocus>
          </InputContainer>
          <Result formInvalid={this.props.formInvalid}>{this.props.formMessage}</Result>
        </TextField>
      </StyledMailBox>
    );
  }

}

export default MailBox
