import React, { Component } from "react"
import styled from "styled-components"

import Link from "../../components/link"


export const StyledMobileMenu = styled.div`
  display: none;

  @media (max-width: 1080px) {
    display: block;
  }
`

export const StyledPopover = styled.div`
  position: fixed;
  display: block;
  inset: 0px;
  // inset hackfix
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // ---
  overflow: auto;
  pointer-events: auto;
  background-color: white;
  opacity: ${ props => props.displayMobileMenu ? "1" : "0" };
  visibility: ${ props => props.displayMobileMenu ? "visible" : "hidden" };
  z-index: 100;
  transform: translate3d(0px, 0px, 0px);
  transition: ${ props => props.displayMobileMenu ? "opacity 0.2s ease-in-out 0.05s" : "visibility 0s ease 0.5s, opacity 0.2s ease-in-out 0s" };
`

export const Box = styled.div`
  box-sizing: border-box;
  margin: 0px;
  padding: 0px 2rem;
  height: 100%;

  @media screen and (min-width: 375px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: 520px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const Flex = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 0px;
`

export const StyledDropdownTrigger = styled.div`
  cursor: pointer;
  color: inherit;
  opacity: 0.5;
  transition: color 0.1s ease 0s, opacity 0.1s ease 0s;
  position: relative;
  padding: 0.75rem 0px;

  &:active {
    opacity: 1;
  }
`

export const StyledDropdownContent = styled.div`
  position: relative;
  overflow: hidden;
  ${ props => props.isOpen ===  true ? "padding-top: 0.5rem" : ""};
  ${ props => props.isOpen ===  true ? "margin-bottom: 1.75rem" : ""};
  max-height: ${ props => props.isOpen ===  true ? "150vh" : "0px"};
  transition: max-height 0.2s ease-in 0s, padding 0.2s ease 0s;
  display: ${ props => props.isOpen ===  true ? "block" : "none" };

  & > div {
    margin: 0px !important;
  }
`

export const StyledGroup = styled.div``

export const StyledOptionList = styled.section`
  margin: 0px;
`

export const StyledLink = styled(Link)`
  display: inline-block;
  color: rgb(139, 149, 158);
  text-decoration: none;
  transition: color 0.1s ease 0s, opacity 0.1s ease 0s;
`

export const StyledHint = styled.span`
  text-transform: none;
  position: relative;
  font-size: 0.75rem;
  color: rgb(255, 0, 135);
  bottom: 1rem;
  line-height: normal;
  font-weight: 600;
  margin-right: -0.8rem;
`

export const StyledLinkWrapper= styled.div`
  position: relative;
  margin: 0px;
  width: 100%;

  & > a {
    padding: 0.8rem 1.6rem;
    display: block;
    width: 100%;
  }

  & a {
    padding: 0.5rem 1rem;
  }

  & > a:hover,
  & > a:active  {
    color: rgb(25, 28, 31);
  }

  & > a ${StyledHint} {
    display: inline-block;
    top: -1em;
  }
`

export const PopoverLinks = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;

  & ${StyledLink} {
    display: block;
    padding: 0.75rem 0px;
    margin: 0px;
  }
`

export const StyledMenuIcon = styled.div`
  position: ${ props => props.displayMobileMenu ? "fixed" : "absolute" };
  top: 2rem;
  right: 2rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  margin-left: auto;
  z-index: 101;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 1.125rem;
    height: 0.125rem;
    left: 0.3rem;
    border-radius: 0.125rem;
    background-color: rgb(139, 149, 158);
    transition: background-color 0.2s ease 0s, transform 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0s;
  }

  &:before {
    top: 0.5rem;
    transform: ${ props => props.displayMobileMenu ? "translateY(0.3125rem) rotate(-45deg)" : "translateY(0.3125rem)" };
  }

  &:after {
    top: 1.125rem;
    transform: ${ props => props.displayMobileMenu ? "translateY(-0.3125rem) rotate(45deg)" : "translateY(0.3125rem)" };
  }
`

class MobileMenu extends Component {

  state = {
    displayMobileMenu: false,
    dropdownOpen: -1,
  }

  toggleMobileMenu = () => {
    this.setState(prevState => {
      return {
        displayMobileMenu: !prevState.displayMobileMenu,
        dropdownOpen: -1,
      }
    })
  }

  toggleMenu = (idx) => {
    this.setState({
      dropdownOpen: idx === this.state.dropdownOpen ? -1 : idx
    });
  }

  render() {
    return (
      <StyledMobileMenu>
        <StyledPopover displayMobileMenu = { this.state.displayMobileMenu }>
          <Flex>
            <PopoverLinks>
              {Object.keys(this.props.sitemap).map((menu, idx) => (
              Array.isArray(this.props.sitemap[menu]) &&
              <Wrapper key={idx}>
                <StyledDropdownTrigger onClick={ () => this.toggleMenu(idx) }>
                  <span>{menu}</span>
                </StyledDropdownTrigger>
                <StyledDropdownContent isOpen={ this.state.dropdownOpen === idx }>
                  <div>
                    <StyledGroup>
                      <StyledOptionList>
                      {this.props.sitemap[menu].map((submenu, idx2) => (
                        submenu.show &&
                        <StyledLinkWrapper key={idx2}>
                          <StyledLink to={submenu.link}>{submenu.name}
                          {submenu.soon &&
                            <StyledHint>Próximamente</StyledHint>
                          }
                          </StyledLink>
                        </StyledLinkWrapper>
                      ))}
                      </StyledOptionList>
                    </StyledGroup>
                  </div>
                </StyledDropdownContent>
              </Wrapper>
              ))}
            </PopoverLinks>
          </Flex>
        </StyledPopover>
        <StyledMenuIcon displayMobileMenu = { this.state.displayMobileMenu } onClick = { this.toggleMobileMenu } />
      </StyledMobileMenu>
    );
  }

}

export default MobileMenu
