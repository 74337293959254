import React, { Component } from "react"
import styled from "styled-components"

import Link from "../link"


export const StyledDesktopMenu = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1080px) {
    display: none;
  }
`

export const LeftPart = styled.div`
  text-transform: uppercase;
  margin-left: 2rem;
`

export const RightPart = styled.div`
  margin-left: auto;
`

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 2rem;

  &:focus {
    outline: currentcolor none medium;
  }
`

export const StyledDropdownTrigger = styled.div`
  cursor: pointer;
  color: inherit;
  opacity: 0.5;
  transition: color 0.1s ease 0s, opacity 0.1s ease 0s;

  &:hover {
    opacity: 1;
  }
`

export const StyledDropdownContent = styled.div`
  display: ${ props => props.isOpen ===  true ? "block" : "none" };
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 4px 0px, rgba(0, 0, 0, 0.08) 0px 8px 16px 0px;
  position: absolute;
  margin-top: 0.5rem;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: white none repeat scroll 0% 0%;
  padding: 0.5rem 0px;
`

export const StyledGroup = styled.div``

export const StyledOptionList = styled.section`
  margin: 0px;
`

export const StyledLink = styled(Link)`
  display: inline-block;
  color: rgb(139, 149, 158);
  text-decoration: none;
  transition: color 0.2s ease 0s;

  display: inline-block;
  margin-right: 1.7rem;

  white-space: nowrap;
  margin-right: 2rem;

  transition: color 0.1s ease 0s, opacity 0.1s ease 0s;

  &:hover {
    color: rgb(25, 28, 31);
  }

  &:last-child {
    margin-right: 0px;
  }
`

export const StyledHint = styled.span`
  text-transform: none;
  position: relative;
  font-size: 0.75rem;
  color: rgb(255, 0, 135);
  bottom: 1rem;
  line-height: normal;
  font-weight: 600;
  margin-right: -0.8rem;
`

export const StyledLinkWrapper= styled.div`
  position: relative;
  margin: 0px;
  width: 100%;

  & > a {
    padding: 0.8rem 1.6rem;
    display: block;
    width: 100%;
  }

  & > a:hover,
  & > a:active  {
    color: rgb(25, 28, 31);
  }

  & > a ${StyledHint} {
    display: inline-block;
    top: -1em;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-shrink: 0;
`

export const Button = styled.button`
  display: inline-block;
  border: medium none;
  border-radius: 2em;
  line-height: 1.5em;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  background-color: rgb(255, 0, 135);
  padding: 0.625em 1.5em;
`

class DesktopMenu extends Component {

  state = {
    dropdownOpen: -1,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    // clicked elem outside of menu wrapper node
    if (!this.node.contains(e.target)) {
      this.setState({
        dropdownOpen: -1
      });
    }
  }

  toggleMenu = (idx) => {
    this.setState({
      dropdownOpen: idx === this.state.dropdownOpen ? -1 : idx
    });
  }

  render() {
    return (
      <StyledDesktopMenu>
        <LeftPart></LeftPart>
        <RightPart ref={node => this.node = node}>
          {Object.keys(this.props.sitemap).map((menu, idx) => (
          Array.isArray(this.props.sitemap[menu]) &&
          <Wrapper key={idx}>
            <StyledDropdownTrigger onMouseEnter={ () => this.toggleMenu(idx) } onClick={ () => this.toggleMenu(idx) }>
              <span>{menu}</span>
            </StyledDropdownTrigger>
            <StyledDropdownContent isOpen={ this.state.dropdownOpen === idx }>
              <div>
                <StyledGroup>
                  <StyledOptionList>
                  {this.props.sitemap[menu].map((submenu, idx2) => (
                    submenu.show &&
                    <StyledLinkWrapper key={idx2}>
                      <StyledLink to={submenu.link}>{submenu.name}
                      {submenu.soon &&
                        <StyledHint>Próximamente</StyledHint>
                      }
                      </StyledLink>
                    </StyledLinkWrapper>
                  ))}
                  </StyledOptionList>
                </StyledGroup>
              </div>
            </StyledDropdownContent>
          </Wrapper>
          ))}
        </RightPart>
        <ButtonWrapper>
          <Button onClick={ () => this.props.togglePopover() }>Empezar</Button>
        </ButtonWrapper>
      </StyledDesktopMenu>
    );
  }

}

export default DesktopMenu
