import React, { Component } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"

import MailBox from "./mailbox"


export const StyledPopover = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  height: 100%;
  display: block;
  opacity: ${ props => props.isVisible ? "1" : "0" };
  visibility: ${ props => props.isVisible ? "visible" : "hidden" };
  z-index: 100;
  transition: opacity 0.2s ease-in-out 0.05s;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  -moz-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  overflow: scroll;
`

export const StyledSubscribePopup = styled.div`
  margin: auto;
  max-width: 86rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  padding: 2.75rem 6rem 2rem;
  z-index: 50;
`

export const StyledHeading = styled.h2`
  font-family: "Basier Circle", sans-serif;
  font-weight: 600;
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 1.5rem;
  margin: 0px;

  @media (min-width: 380px) {
    font-size: 2rem;
  }

  text-align: center;
`

export const StyledSubtitle = styled.p`
  text-align: center;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
`

export const StyledForm = styled.div`
  margin: auto;
  text-align: left;
  width: 100%;
  max-width: 28rem;
`

export const Button = styled.button`
  display: inline-block;
  padding: 1em 4.125em;
  border: medium none;
  border-radius: 2em;
  line-height: 1.5em;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin-bottom: 0.5em;
  background-color: rgb(255, 0, 135);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

export const WidgetSubmitBtn = styled(Button)`
  width: 100%;
  max-width: 28rem;
  margin: 0px auto;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`

export const StyledCloseButton = styled.div`
  position: absolute;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  top: 2rem;
  right: 2rem;
  z-index: 100;
  height: 3.5rem;
  width: 3.5rem;
  padding: 1rem;
  stroke: rgb(117, 125, 133);
  fill: rgb(117, 125, 133);
  color: rgb(117, 125, 133);
  border: 1px solid rgb(206, 210, 214);
  cursor: pointer;
  border-radius: 50%;
`


class Popover extends Component {

  state = {
    formFocused: false,
    formEmail: null,
    formInvalid: false,
    formMessage: null,
    formBtnDisabled: true,
  }

  constructor() {
    super();

    this.emailInput = React.createRef();
  }

  _handleOnFocus = (e) => {
    this.setState({
      formFocused: true
    });
  }

  _handleOnBlur = (e) => {
    if (e.target.value)
      return;

    this.setState({
      formFocused: false
    });
  }

  _handleOnInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {[name]: value},
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {
    let formBtnDisabled = this.state.formBtnDisabled;

    switch(fieldName) {
      case 'email':
        formBtnDisabled = !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        break;
      default:
        break;
    }

    this.setState({
      formBtnDisabled: formBtnDisabled,
    });
  }

  _handleSubmit = (e) => {
      e.preventDefault();

      this.setState({
        formBtnDisabled: true
      });

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
            console.log(result, msg);

            this.setState({
              formInvalid: result === 'success' ? false : true,
              formMessage: result === 'success' ? "Gracias por subscribirte!" : "Este correo ya esta registrado",
              formBtnDisabled: false
            });
          });
  }

  render() {
    return (
      <StyledPopover isVisible={ this.props.showPopover }>
        <StyledContent>
          <StyledSubscribePopup>
            <StyledHeading>Estás a un click de ser parte de KKO</StyledHeading>
            <StyledSubtitle>Únete a la lista de espera y conoce más.</StyledSubtitle>
            <StyledForm>
              <MailBox
                ref={this.emailInput}
                formFocused={this.state.formFocused}
                handleOnFocus={this._handleOnFocus}
                handleOnBlur={this._handleOnBlur}
                handleOnInput={this._handleOnInput}
                formInvalid={this.state.formInvalid}
                formMessage={this.state.formMessage}
              />
            </StyledForm>
            <WidgetSubmitBtn onClick={this._handleSubmit} disabled={this.state.formBtnDisabled}>Regístrate</WidgetSubmitBtn>
          </StyledSubscribePopup>
        </StyledContent>
        <StyledCloseButton onClick={() => this.props.togglePopover()}>X</StyledCloseButton>
      </StyledPopover>
    );
  }

}

export default Popover
