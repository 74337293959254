import React, { Component } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import "./layout.css"
import '../css/typography.css'

import Navbar from "./navbar/navbar"
import Footer from "./footer"
import Popover from "./popover"

class Layout extends Component {

  sitemap = {
    'Cuentas': [
      {
        name: 'Estandar',
        link: '/cuenta-estandar',
        soon: false,
        show: true
      },
      {
        name: 'Premium',
        link: '/cuenta-premium',
        soon: true,
        show: true
      }
    ],
    'Productos': [
      {
        name: 'Pagos de servicios',
        link: '/pagos-de-servicios',
        soon: false,
        show: true
      },
      {
        name: 'Transferencias de dinero',
        link: '/transferencias-de-dinero',
        soon: false,
        show: true
      },
      {
        name: 'Cuenta multidivisas',
        link: '/cuenta-multidivisas',
        soon: true,
        show: true
      },
      {
        name: 'Mis amigos',
        link: '/mis-amigos',
        soon: false,
        show: true
      },
      {
        name: 'Presupuestos',
        link: '/presupuestos',
        soon: false,
        show: true
      },
      {
        name: 'Mis ahorros',
        link: '/mis-ahorros',
        soon: false,
        show: true
      }
    ],
    'Empresa': [
      {
        name: 'Nosotros',
        link: '/acerca-de-nosotros',
        soon: false,
        show: true
      },
      {
        name: 'Blog',
        link: 'https://blog.kko.mx',
        soon: false,
        show: true
      },
      {
        name: 'Jobs',
        link: 'https://www.linkedin.com/company/kko-bank/jobs/',
        soon: false,
        show: true
      }
    ]
  }

  state = {
    showPopover: false
  }

  togglePopover = () => {
    this.setState(prevState => {
      return {
        showPopover: !prevState.showPopover
      }
    })
  }

  render() {
    const { children } = this.props;

    return (
      <>
        <Helmet
          bodyAttributes={ this.state.showPopover ?
            { style:"overflow: hidden" } : {}
          }
        />
        <Navbar sitemap={ this.sitemap } togglePopover={ this.togglePopover } />
        <main>
          {children}
        </main>
        <Footer sitemap={ this.sitemap } />
        <Popover showPopover={ this.state.showPopover } togglePopover={ this.togglePopover } />
      </>
    )
  }

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
