import React, { Component } from "react"
import styled from "styled-components"

import logo from '../../images/logo.svg'

import Link from "../link"

import DesktopMenu from "./desktop-menu"
import MobileMenu from "./mobile-menu"


export const StickyWrapper = styled.div`
  position: ${ props => props.sticked ? "fixed" : "absolute" };
  ${ props => props.sticked ? "top: 0px" : "" };
  left: 0px;
  right: 0px;
  background: ${ props => props.sticked ? "rgb(255, 255, 255)" : "transparent" } none repeat scroll 0% 0%;
  padding: ${ props => props.sticked ? "1.25rem" : "2.5rem" } 0px;
  z-index: 11;
  ${ props => props.sticked ? "box-shadow: rgba(25, 28, 31, 0.04) 0px 8px 16px, rgba(25, 28, 31, 0.04) 0px -1px 0px" : "" };

  @media (max-width: 519px) {
    padding: 2rem 0px;
  }
`

export const Header = styled.div`
  max-width: 86rem;
  margin: 0px auto;
  padding: 0px 3rem;

  @media (max-width: 519px) {
    padding: 0px 2rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  margin: 0px auto;
  width: 100%;
`

export const LogoLink = styled(Link)`
  display: flex;
  -moz-box-align: center;
  align-items: center;
`

export const Logo = styled.span`
  margin: 0px;
  display: flex;
  font-size: 2rem;
  color: rgb(255, 255, 255);
  justify-content: normal;
  height: 1.75rem;
  width: 6.5rem;
`

export const Menu = styled.div`
  flex: 1 1 0%;
`

class Navbar extends Component {

  state = {
    sticked: false,
  }

  handleScroll = () => {
    if (window.innerWidth > 1080 && window.scrollY > 25) {
      this.setState({
        sticked: true
      });
    } else {
      this.setState({
        sticked: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <StickyWrapper sticked = { this.state.sticked }>
        <Header>
          <Wrapper>
            <LogoLink to="/">
              <Logo>
                <img src={ logo } width="100" alt="KKO logo" />
              </Logo>
            </LogoLink>
            <Menu>
              <MobileMenu sitemap={ this.props.sitemap } />
              <DesktopMenu sitemap={ this.props.sitemap } togglePopover={ this.props.togglePopover } />
            </Menu>
          </Wrapper>
        </Header>
      </StickyWrapper>
    );
  }

}

export default Navbar
