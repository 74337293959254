import React from "react"
import styled from "styled-components"

import Link from "./link"

export const StyledFooter = styled.footer`
  padding: 4rem 1rem 4rem 5rem;
  margin-top: auto;
  background-color: rgb(243, 244, 245);
  color: rgb(139, 149, 158);
  text-align: left;

  @media (max-width: 1080px) {
    padding: 4rem 2rem;
  }
`

export const Wrapper = styled.div`
  max-width: 86rem;
  margin: 0px auto;
`

export const FooterMenu = styled.div`
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
`

export const Wrap = styled.div`
  display: flex;

  @media (max-width: 880px) {
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  display: inline-block;
  min-width: 9.5rem;
  padding-right: 1.5rem;
  -moz-box-pack: justify;
  justify-content: space-between;

  @media (max-width: 1240px) {
    min-width: 20%;
    margin-top: 4rem;
  }

  @media (max-width: 880px) {
    min-width: 50%;
  }

  @media (max-width: 420px) {
    min-width: 100%;
  }
`

export const SubTitle = styled.div`
  margin-bottom: 1.25rem;
  color: rgb(25, 28, 31);
  rgb(25, 28, 31);
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const StyledLink = styled(Link)`
  display: inline-block;
  color: rgb(139, 149, 158);
  text-decoration: none;
  transition: color 0.2s ease 0s;

  &:hover {
    color: rgb(25, 28, 31);
  }

  &:focus {
    outline: currentcolor none 0px !important;
  }
`

export const StyledHint = styled.span`
  text-transform: none;
  position: relative;
  font-size: 0.75rem;
  color: rgb(255, 0, 135);
  bottom: 1rem;
  line-height: normal;
  font-weight: 600;
  margin-right: -0.8rem;
`

export const StyledSubMenu = styled.div`
  box-sizing: border-box;
  line-height: 1.5em;
  outline: currentcolor none medium;

  & ${StyledLink} {
    display: block;
    margin-top: 1rem;
    white-space: nowrap;
  }
`

export const SubMenu = styled.span`
  & ${StyledLink} {
    margin-right: 1.5rem;
  }
`

export const CopyrightSpacer = styled.div`
  margin-top: 3rem;
`

export const Copyright = styled.div`
  font-size: 0.75rem;
  line-height: 1.3;
`

export const CopyrightText = styled.span`
  margin-right: 2rem;
`

class Footer extends React.Component {

  render() {
    return (
      <StyledFooter>
        <Wrapper>
          <FooterMenu>
            <div></div>
            <Wrap>
              {Object.keys(this.props.sitemap).map((menu, idx) => (
              Array.isArray(this.props.sitemap[menu]) &&
              <Column key={idx}>
                <SubTitle>{menu}</SubTitle>
                <StyledSubMenu>
                  {this.props.sitemap[menu].map((submenu, idx2) => (
                    submenu.show &&
                    <StyledLink to={submenu.link} key={idx2}>{submenu.name}
                    {submenu.soon &&
                      <StyledHint>Próximamente</StyledHint>
                    }
                    </StyledLink>
                  ))}
                </StyledSubMenu>
              </Column>
              ))}
              <Column>
                <SubTitle>Siguenos</SubTitle>
                <StyledSubMenu>
                  <StyledLink target="_blank" href="https://www.facebook.com/KKO-2331631266919514">Facebook</StyledLink>
                  <StyledLink target="_blank" href="https://www.instagram.com/kkobank/">Instagram</StyledLink>
                </StyledSubMenu>
              </Column>
              <Column>
                <SubTitle>Contacto</SubTitle>
                <StyledSubMenu>
                  <StyledLink target="_blank" href="https://wa.me/33767633370">Whatsapp</StyledLink>
                </StyledSubMenu>
              </Column>
            </Wrap>
          </FooterMenu>
          <CopyrightSpacer />
          <Copyright>
            <p>
              <CopyrightText>Copyright © {new Date().getFullYear()} KKO</CopyrightText>
              <SubMenu>
                <StyledLink href="/aviso-de-privacidad">Aviso de privacidad</StyledLink>
              </SubMenu>
            </p>
            <div>
              KKO's statements regarding its plans, directions and intent in connection with Mexico are subject to local laws, regulations and obtaining the necessary regulatory approvals and authorisations. Information on this website is subject to change or withdrawal without notice at KKO's sole discretion. Information on this website is intended to outline our general product direction and it should not be relied on in making a purchasing decision. This information is not an offer, commitment, promise or legal obligation to deliver any material, code or functionality. Information about potential future products in Mexico may not be incorporated into any future contract. The development, release and timing of any release in Mexico remains at the sole discretion of KKO.
            </div>
          </Copyright>
        </Wrapper>
      </StyledFooter>
    );
  }

}

export default Footer
